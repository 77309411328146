import React from "react"
import { css, jsx } from "@emotion/core"

class Card extends React.Component {
  render() {
    return (
      <div
        css={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "5px",
          border: "1px solid #c3c4ca",
          marginBottom: "1rem",
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Card
