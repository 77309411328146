import React from "react"
import CommunityLayout from "../../components/layouts/communityLayout"

import ImageCard from "../../components/cards/imageCard.js"

import Courthouse from "../../images/evans-county-courthouse.jpeg"
import Claxton from "../../images/claxton.jpeg"
import Bellville from "../../images/bellville.jpg"
import Daisy from "../../images/daisy.jpg"
import Hagan from "../../images/hagan.jpg"
const GovernmentPage = props => (
  <CommunityLayout pageTitle="Government">
    <p>
      Evans County, created from portions of Bulloch and Tattnall counties, was
      established by an act of the Georgia General Assembly on August 11, 1914.
      The historic Evans County Courthouse was constructed during 1922-23, at a
      cost of $60,000. Designed by architect J.J. Baldwin, the construction
      project was handled by West Point Iron Works.{" "}<br /><br />
    </p>
    <p>
      Evans County is governed by six elected commissioners. The City of
      Claxton, the county seat, is governed by an elected mayor and seven
      council members. The City of Daisy is governed by an elected mayor and
      five council members. The City of Bellville is governed by an elected
      mayor and three council members. The City of Hagan is governed by an
      elected mayor and five council members.<br /><br />
    </p>
    <div>
      <ImageCard src={Courthouse} alt="Evans County Geogria Courthouse">
        <h2>Evans County Board of Commissioners</h2>
        <p>
          3 Freeman Street
          <br />
          Claxton, Georgia 30417
          <br />
          (912) 739-1141
          <br />
          <a href="http://www.evanscounty.org" target="_blank">
            www.evanscounty.org
          </a>
        </p>
        <p>
          The Evans County Board of Commissioners meet on the first Tuesday of
          each month, 6:30 pm, at the Courthouse Annex.{" "}
        </p>
      </ImageCard>
      <ImageCard src={Claxton} alt="Claxton Georgia">
        <h2>City of Claxton</h2>
        <p>"Fruitcake Capital of the World”</p>
        <p>
          PO Box 829
          <br />
          Claxton, Georgia 30417
          <br />
          (912) 739-1712
          <br />
          <a href="http://www.cityofclaxton.net" target="_blank">
            www.cityofclaxton.net
          </a>
        </p>
        <p>
          Claxton City Council meets on the first and third Mondays of each
          month, 6:00 pm, at Claxton City Hall.
        </p>
      </ImageCard>
      <ImageCard src={Bellville} alt="City of Bellville Georgia">
        <h2>City of Bellville</h2>
        <p>
          PO Box 121
          <br />
          Bellville, Georgia 30414
          <br />
          (912) 739-3110
        </p>
        <p>
          Bellville City Council meets on the first Tuesday of each month, 6:30
          pm, at Bellville City Hall.
        </p>
      </ImageCard>
      <ImageCard src={Daisy} alt="City of Daisy Georgia">
        <h2>City of Daisy</h2>
        <p>
          PO Box 210
          <br />
          Daisy, Georgia 30423
          <br />
          (912) 739-2125
        </p>
        <p>
          Daisy City Council meets on the fourth Tuesday of each month, 7:00 pm,
          at Daisy City Hall.
        </p>
      </ImageCard>
      <ImageCard src={Hagan} alt="City of Hagan Georgia">
        <h2>City of Hagan</h2>
        <p>
          PO Box 356
          <br />
          Hagan, GA 30429
          <br />
          (912) 739-3801
        </p>
        <p>
          Hagan City Council meets on the third Tuesday of each month, 6:00 pm,
          at Hagan City Hall.
        </p>
      </ImageCard>
    </div>
  </CommunityLayout>
)

export default GovernmentPage
