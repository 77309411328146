import React from "react"
import { css, jsx } from "@emotion/core"

import Card from "./card"

const breakpoints = [800]

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

class ImageCard extends React.Component {
  render() {
    return (
      <Card>
        <div
          css={{
            display: "flex",
            flexWrap: "wrap",
            [mq[0]]: {
              flexWrap: "nowrap",
            },
          }}
        >
          <div css={{
            margin: '0 auto',
            [mq[0]]: {
              margin: '0',
            },
          }}>
            <img
              height="180"
              width="280"
              src={this.props.src}
              alt={this.props.alt || ""}
              css={{
                border: `1px solid lightgray`, 
              }}
          />
          </div>
          <div css={{marginLeft: `25px`}}>{this.props.children}</div>
        </div>
      </Card>
    )
  }
}

export default ImageCard
