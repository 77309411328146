import React from "react"
import { css, jsx } from "@emotion/core"

import SiteLayout from "./siteLayout.js"
import SidebarWrapper from "../sidebar/sidebarWrapper.js"
import MainWrapper from "../sidebar/mainWrapper.js"
import QuickLinkMenu from "../sidebar/quickLinkMenu.js"
import MemberDirectoryLink from "../sidebar/memberDirectoryLink.js"
import UpcomingEvents from "../sidebar/upcomingEvents.js"

class CommunityLayout extends React.Component {
  render() {
    return (
      <SiteLayout>
        <div
          css={{
            position: "relative",
            maxWidth: "1640px",
            margin: "0 auto",
          }}
        >
          <div css={{ padding: "0px 25px" }}>
            <h1>{this.props.pageTitle}</h1>
          </div>
          <MainWrapper>{this.props.children}</MainWrapper>
          <SidebarWrapper>
            <QuickLinkMenu
              title="Community Quick Links"
              links={[
                { to: "/community/government", text: "Government" },
                { to: "/community/education", text: "Education" },
                { to: "/community/healthcare", text: "Healthcare" },
                { to: "/community/recreation", text: "Recreation" },
                { to: "/community/attractions", text: "Attractions" },
              ]}
            />
            <MemberDirectoryLink />
            <UpcomingEvents />
          </SidebarWrapper>
        </div>
      </SiteLayout>
    )
  }
}
export default CommunityLayout
